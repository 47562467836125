import React from 'react';
import Placeholder from '../../../core/Components/Placeholder';

function SitecoreFormContainer({ rendering }) {
  return (
    <div
      id={rendering.fields.id.value}
      className={rendering.fields.class.value}
    >
      <Placeholder
        name="sitecore-form-container"
        rendering={rendering}
        placeholderData={rendering.fields}
      />
    </div>
  );
}

export default SitecoreFormContainer;
